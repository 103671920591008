import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../modules/main/main.page.module').then((m) => m.MainPageModule)
  },
  {
    path: '*',
    redirectTo: '',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled', 
      preloadingStrategy: PreloadAllModules 
    })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
